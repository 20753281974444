import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { exhaustMap, finalize, map, tap } from 'rxjs/operators';

import {
  EFlashyLanguages,
  FlashyCustomDataService,
  FlashyStoreService,
} from '@flashy/store';

import {
  FlashyContactProperty,
  FlashyContactPropertyField,
  TContactPropertyResource,
} from './flashy-contact-property';

export interface IFlashyCreateContactPropertyParams
  extends FlashyContactPropertyField {
  property: TContactPropertyResource;
}

@Injectable({
  providedIn: 'root',
})
export class FlashyContactPropertyService extends FlashyCustomDataService<FlashyContactProperty> {
  pending = new BehaviorSubject(false);
  uniqueKey: keyof FlashyContactProperty = 'key';
  isLoading$ = this.storeService.isLoading$;

  constructor(private storeService: FlashyStoreService) {
    super();
  }

  get flatFields$(): Observable<FlashyContactPropertyField[]> {
    return this.entities$.pipe(
      exhaustMap((entities) => {
        if (!entities.length && !this.pending.getValue()) {
          return this.getAll();
        } else {
          return of(entities);
        }
      }),
      map((props) =>
        props.reduce((prev: FlashyContactPropertyField[], { fields }) => {
          prev.push(...fields);
          return prev;
        }, [])
      )
    );
  }

  getAll(language?: EFlashyLanguages): Observable<FlashyContactProperty[]> {
    this.pending.next(true);
    return this.storeService
      .makeHttpRequest<FlashyContactProperty[]>(
        'GET',
        'properties',
        {},
        {
          ...(language ? { language } : {}),
          property: 'contact',
        }
      )
      .pipe(
        tap((entities) => this.addEntities(entities)),
        finalize(() => this.pending.next(false))
      );
  }

  add(
    params: IFlashyCreateContactPropertyParams
  ): Observable<FlashyContactProperty> {
    return this.storeService
      .makeHttpRequest<FlashyContactProperty>('POST', 'property', params)
      .pipe
      // tap(entity => this.addEntities([entity]))
      ();
  }

  update(
    params: IFlashyCreateContactPropertyParams
  ): Observable<FlashyContactProperty> {
    return this.storeService
      .makeHttpRequest<FlashyContactProperty>('PUT', 'property', params)
      .pipe(tap((entity) => super.updateEntity(entity)));
  }
}
